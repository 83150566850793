.navbar {
    z-index: 1;
    background-position: center 15% !important;
    background-size: cover !important;
}

.bg-tran {
    background-color: transparent !important;
}

.nav-fixed-tran {
    color: $text-primary !important;
    background-color: transparent !important;
    transition: background-color .5s linear !important;
    box-shadow: unset !important;
}

.nav-fixed-white {
    color: $text-secondary !important;
    background-color: #FAFAFA !important;
    transition: background-color .5s linear !important;
    box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%) !important;
}
