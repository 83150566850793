@import 'variable'; 
@import 'navbar';
@import 'animate.css';       

.bg-section {
    background-color: $bg-section;
}

.MuiInput-root {
    color: $text-secondary;
}
  

.MuiInputBase-formControl {
    color: #000000 !important;
    background-color: rgba(248, 248, 248, 1) !important;
}

.MuiOutlinedInput-notchedOutline {
    border-color: rgba(248, 248, 248, 1) !important;
}

.Mui-focused {
    color: #000000 !important;
}

// TODO find and change name class
// .MuiSvgIcon-root {
//     color: $text-secondary !important;
// }

::-webkit-file-upload-button {
    display: none;
}

::file-selector-button {
    display: none;
}

.MuiPaginationItem-rounded {
    color: #000000 !important;
}

.MuiPagination-ul .Mui-selected {
    color: #FFFFFF !important;
    background-color: $tab-menu-active !important;
}

.carousel-container {
    width: 100%;
}

.react-multi-carousel-dot {
    > button {
        border-color: #C4C4C4 !important;
        background: #C4C4C4;
    }

    &--active > button {
        border-color: #121644 !important;
        background: #121644;
    }
}

 .react-multiple-carousel { 
    &__arrow {
        color: $text-primary;
        background: $button-bg-primary !important;
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: .3 !important;

        &:hover {
            opacity: 1 !important;
        }

        &--right {
            right: 0 !important; 
        }
    
        &--right::before {
            content: unset !important;
        }
    
        &--left {
            left: 0 !important; 
        }

        &--left::before {
            content: unset !important;
        }
    }

}

.app-container {
    position: relative;
}

  
.backdrop {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
}

.react-multi-carousel-list {
    align-items: flex-start;
}

.carousel-container-careers {
    height: 350px;
}