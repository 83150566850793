@font-face {
    font-family: 'Sukhumvit Set';
    src: url('../public/font/SukhumvitSet-Bold.woff2') format('woff2'),
         url('../public/font/SukhumvitSet-Bold.woff') format('woff'),
         url('../public/font/SukhumvitSet-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Sukhumvit Set';
    src: url('../public/font/SukhumvitSet-Medium.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

body {
    margin: 0;
    font-family: 'Sukhumvit Set', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}