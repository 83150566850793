$text-primary: #FFFFFF;
$text-secondary: #121516;

$bg-primary: #203478;
$bg-secondary: #1A2E71;
$bg-section: #121516;

$icon-primary: #293E98;
$icon-secondary: #1F2263;

$title-color: #111441;
 
$input-bg: #F8F8F8;
$card-bg: #F8F8F8;

$tab-menu: #F2F2F2;
$tab-menu-active: #030303;



$button-bg-primary: #121516; 
$button-bg: #121543; 

